<!-- Copyright © 2024 Insurance Services Office, Inc. All rights reserved -->

<app-header></app-header>
<app-loading></app-loading>
<div class="d-flex" style="padding-top: 43px; flex: 1">
  <div class="sidebar-nav">
    <app-left-menu></app-left-menu>
  </div>
  <div class="main-content-wrapper">
    <router-outlet></router-outlet>
    <app-toasts></app-toasts>
  </div>
</div>
<app-footer></app-footer>
