import { Component } from '@angular/core';

@Component({
    selector: 'app-folder-legend',
    template: ` <i class="fa-solid fa-folder fa-fw" style="color: #6acae9;" title={{nonMaterialized}}></i> Non-materialized
                <i class="fa-stack fa-fw" style="color: #6acae9; width: 1.28571em;" title={{materialized}}>
                    <i class="fa-solid fa-folder fa-stack-1x"></i>
                    <i class="fa-solid fa-star fa-stack-1x fa-inverse" style="font-size: 5px; bottom:-3px; margin-left: 15%;"></i>
                </i> Materialized `
    
})
            
export class FolderLegendComponent {    
    nonMaterialized: string = "Simply folders in S3 bucket.";
    materialized: string = "Folders in S3 bucket that have been initialized as datasets. This means all the files in this folder must be of the same schema or file layout and uploading any new files with differing file layout will not result in rediscovery/adjustment of the schema. Such files could potentially fail to be uploaded."          
}