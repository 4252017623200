import { Injectable } from '@angular/core';
import { UserInfo } from '../models/user-info.model';
import { IAppConfig } from '../models/config';
import { AppService } from '../app.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    appConfig: IAppConfig;

    constructor(private appService: AppService) {
        this.appConfig = this.appService.readConfig();

    }

    userInfo: UserInfo;
    async loadUserInfo(): Promise<UserInfo> {
        const url = `${this.appConfig.apiUrl}/User?id=${this.appConfig.username}`;
        try {
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Cache: 'no-cache',
                    identity: this.appConfig.username
                }
            });
            const userInfo = await res.json();
            this.userInfo = userInfo;
            console.log(JSON.stringify(userInfo));
            return userInfo;
        } catch (err) {
            console.log(err);
        }
    }

    hasViewPermission(resource: string): boolean {
        const permissions = this.userInfo.permissions || [];
        return permissions.some(
            c =>
                c.mayView &&
                c.resourceName.trim() === resource.trim() &&
                c.resourceCategoryCode === this.appConfig.applicationName
        );
    }

    hasCreatePermission(resource: string): boolean {
        const permissions = this.userInfo.permissions || [];
        return permissions.some(
            c => c.mayEdit && c.resourceName.trim() === resource.trim()
        );
    }
}