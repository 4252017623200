import { Injectable } from '@angular/core';
import { uuid } from 'src/app/services/helpers';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _loading: boolean = false;
  public get loading() { return this._loading; }

  requestList: RequestLoaderDto[] = [];

  requestStart(request: RequestLoaderDto) {
    this._loading = true;
    this.requestList.push(request);
  }
  requestFinished(request: RequestLoaderDto) {
    const indexDeletedrequest = this.requestList.indexOf(request);
    if (indexDeletedrequest < 0) {
      return;
    }


    this.requestList.splice(indexDeletedrequest, 1);
    if (this.requestList.length == 0) {
      this._loading = false;
    }
  }
}
export class RequestLoaderDto {
  public id: string;
  constructor(public description?: string) {
    this.id = uuid();
  }
}