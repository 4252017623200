import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-form-header',
    templateUrl: './form-header.component.html',
    styleUrls: []
  })
  export class FormHeaderComponent {
    @Input() label: string;
    @Input() formStatus: boolean;
    @Input() displaySave: boolean;
    @Output() save = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    saveForm(): void {
      this.save.emit();
    }
  
    cancelForm() {
      this.cancel.emit();
    }
  
  }