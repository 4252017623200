<!-- Copyright © 2024 Insurance Services Office, Inc. All rights reserved -->

<div class="ag-cell-button-bar">
    <div class="ag-cell-button-bar-btn" *ngIf="params.editable">
      <a (click)="onClickEdit($event)" title="Edit">
        <i class="fa fa-edit fa-xl" ngbTooltip="Edit" openDelay="5" container="body" placement="bottom"></i>
      </a>
    </div>
    <div class="ag-cell-button-bar-btn" *ngIf="params.runnable">
      <a (click)="onClickRun($event)" title="Run">
        <i class="fa fa-cogs fa-xl" ngbTooltip="Run" openDelay="5" container="body" placement="bottom"></i>
      </a>
    </div>
    <div class="ag-cell-button-bar-btn" *ngIf="params.deletable">
      <a (click)="onClickDelete($event)" title="Delete">
        <i class="fa fa-trash fa-xl" ngbTooltip="Delete" openDelay="5" container="body" placement="bottom"></i>
      </a>
    </div>
    <div class="ag-cell-button-bar-btn" *ngIf="params.downloadable">
      <a (click)="onClickDownload($event)" title="Download">
        <i class="fa fa-download fa-xl" ngbTooltip="Download" openDelay="5" container="body" placement="bottom"></i>
      </a>
    </div>
</div>