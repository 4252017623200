import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VrskAuthOktaService } from '@uw-verisk/auth-okta';
import { Router } from '@angular/router'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private vrskAuthOktaService: VrskAuthOktaService, private router: Router) { }
  intercept(request, next) {
    // Handle response
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse)
        {
            if(event?.body && event?.body?.code === "UNAUTHORIZED"){
              this.router.navigateByUrl('page-not-authorized', { skipLocationChange: true });
              return; 
            }
        }
      }),
      catchError(error => {
        //handle specific errors
        let errorIndex = error.status.toString();
        if (errorIndex === '401') {
          localStorage.removeItem("loggedin_users");
          if(this.vrskAuthOktaService?.oktaAuth)
          {
            this.vrskAuthOktaService?.oktaAuth?.signOut();
          }
        }
        return throwError(error);
      })
    );
  }
}