import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'file-selector-renderer',
    template: `<input *ngIf="isFile"
    type="checkbox" style="margin-bottom: 3px;vertical-align: middle;" class="mr-2"
    (click)="checkedHandler($event)"
    [checked]="selected"
    /><span>{{ params.value }}`
})

export class FileSelectorRenderer implements ICellRendererAngularComp {
    public params: ICellRendererParams;
    public isFile = false;
    public selected = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.isFile = params.data.fileState === "Uploaded";
        this.selected = params.data.selected === true;
    }

    refresh(): boolean {
        return true;
    }

    checkedHandler(event) {
        let checked = !!event.target.checked;
        this.params.data.selected = checked;
        this.selected = checked;
        this.params.context.onFileSelected(checked,this.params.data);
    }
}