export interface IAppConfig {
    username: string;
    userINumber: string;
    redirectUrl: string;
    clientId: string;
    loginUrl: string;
    gatewayLoginUrl: string;
    issuer: string;
    responseType: string;
    logoutUrl: string;
    applicationName: string;
    apiUrl: string;
    analyticsTag: string;
    kmsKeyId: string;
    delimiters: {};
}

export let Config = {
    username: 'I29733'
}