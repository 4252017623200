import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICustomerDto } from 'src/app/dto/ICustomerDto';
import { DataDropService } from 'src/app/services/http.datadrop.service';
import { IRecipeIdentification } from 'src/app/feature-recipe/recipe-item/dto';
import { RecipeStateStoreService } from '../service/recipe-state-store.service';


@Component({
  selector: 'app-recipe-identification-panel',
  templateUrl: './recipe-identification-panel.component.html',
  styleUrls: [],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RecipeIdentificationPanelComponent), multi: true }
  ]
})
export class RecipeIdentificationPanelComponent implements OnInit, ControlValueAccessor {
  recipeForm: FormGroup;
  customerList: ICustomerDto[];

  selectedCustomerId: FormControl = new FormControl();
  selectedSourceSystemId: FormControl = new FormControl();
  selectedProductId: FormControl = new FormControl();
  selectedLobId: FormControl = new FormControl();
  selectedDatasetId: FormControl = new FormControl();

  filteredProductList = [];
  filteredSourceSystemList = [];
  filteredLobList = [];
  filteredDataSet = [];

  @Input() showDatasetList: boolean = true;
  lastValueRecipeIdentificationChange = null;
  _onChange;
  _onTouched;
  constructor(private datadropService: DataDropService,
    private recipeStateService:RecipeStateStoreService) {
    datadropService.getCustomer().subscribe(x => {
      this.recipeStateService.setCustomers(x.data);
      this.customerList = x.data
    });
  }
  writeValue(recipeIdentification: IRecipeIdentification): void {
     // This is intentional 
     // override for implementing controlvalue accessor
     // DO NOT remove this comment
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  public updateRecipeIdentification() {
    const currentVal = this.setCurrentStateValues();
    if (JSON.stringify(currentVal) !== JSON.stringify(this.lastValueRecipeIdentificationChange)) {
      this.lastValueRecipeIdentificationChange = currentVal;
      if (this._onChange) {
        this._onChange(currentVal);
        this._onTouched(currentVal);
      }
    }
  }

  private setCurrentStateValues() {
    return {
      customerId: this.selectedCustomerId.value,
      customerName: this.selectedCustomerId.value != undefined ? this.customerList.find(x => x.id == this.selectedCustomerId.value).customerName : null,
      datasetId: this.selectedDatasetId.value,
      datasetName: this.selectedDatasetId ? this.filteredDataSet.find(x => x.datasetId == this.selectedDatasetId.value)?.datasetName : null,
      lobId: this.selectedLobId.value,
      lobName: this.selectedLobId.value != undefined ? this.filteredLobList.find(x => x.lobId == this.selectedLobId.value).lobName : null,
      productId: this.selectedProductId.value,
      productName: this.selectedProductId.value != undefined ? this.filteredProductList.find(x => x.productId == this.selectedProductId.value).productName : null,
      sourceSystemId: this.selectedSourceSystemId.value,
      sourceSystemName: this.selectedSourceSystemId.value != undefined ? this.filteredSourceSystemList.find(x => x.sourceSystemId == this.selectedSourceSystemId.value).sourceSystemName : null,
      recipeId: null,
    };
  }

  ngOnInit() {
    this.initUI();
  }

  public initUI() {
    if(this.recipeStateService.getRecipeState().customers.length > 0){
      this.setRetainedRecipeVals();
    }
  }

  private setRetainedRecipeVals(){
    this.customerList = this.recipeStateService.getRecipeState().customers;
    this.filteredSourceSystemList = this.recipeStateService.getRecipeState().sourceSystems;
    this.filteredProductList = this.recipeStateService.getRecipeState().products;
    this.filteredLobList = this.recipeStateService.getRecipeState().lobs;
    this.filteredDataSet = this.recipeStateService.getRecipeState().datasets;
    this.selectedCustomerId.patchValue(this.recipeStateService.getRecipeState().selectedCustomerId);    
    this.selectedSourceSystemId.patchValue(this.recipeStateService.getRecipeState().selectedSourceSystemId);
    this.selectedProductId.patchValue(this.recipeStateService.getRecipeState().selectedProductId);
    this.selectedLobId.patchValue(this.recipeStateService.getRecipeState().selectedLobId);
    this.selectedDatasetId.patchValue(this.recipeStateService.getRecipeState().selectedDatasetId);
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const currVal = this.setCurrentStateValues();
      if(this._onChange){
        this._onChange(currVal);
        this._onTouched(currVal);
      }
    }, 0);
  }

  private resetStoreAndForm() {
    this.recipeStateService.resetExceptCustomer();
    this.filteredDataSet = [];
    this.filteredLobList = [];
    this.filteredProductList = [];
    this.filteredSourceSystemList = [];
    this.selectedLobId.setValue(null);
    this.selectedSourceSystemId.setValue(null);
    this.selectedProductId.setValue(null);
    this.selectedDatasetId.setValue(null);
  }

  onSelectCustomer(customer_id:any){
    if (customer_id) {
      this.recipeStateService.setSelectedCustomerId(customer_id);
      this.datadropService.getSourceSystem(customer_id).subscribe((p) => {
        this.filteredSourceSystemList = p.data;
        this.recipeStateService.setSourceSystems(p.data);
      });
    } else {
      this.filteredSourceSystemList = [];
      this.recipeStateService.setSourceSystems(this.filteredSourceSystemList);
    }
    this.selectedSourceSystemId.setValue(null);
    this.resetStoreAndForm();
    this.recipeStateService.setSelectedSourceSystemId(null);
    this.updateRecipeIdentification();
  }

  onSelectSourceSystem(sourceSystemId:any){
    if (sourceSystemId) {
      this.datadropService.getProduct(this.selectedCustomerId.value, sourceSystemId).subscribe((p) => {
        this.filteredProductList = p.data;
        this.recipeStateService.setSelectedSourceSystemId(sourceSystemId);
        this.recipeStateService.setProducts(p.data);
      });
    } else {
      this.filteredProductList = [];
      this.recipeStateService.setProducts(this.filteredProductList);
    }
    this.selectedProductId.setValue(null);
    this.selectedLobId.setValue(null);
    this.filteredLobList = [];
    this.filteredDataSet = [];
    this.selectedProductId.setValue(null);
    this.selectedDatasetId.setValue(null);
    this.recipeStateService.setSelectedProductId(null);
    this.updateRecipeIdentification();
  }

  onSelectProduct(productId:any){
    if (productId) {
      this.datadropService.getLOB(this.selectedCustomerId.value, this.selectedSourceSystemId.value, productId).subscribe((p) => {
        this.filteredLobList = p.data;
        this.recipeStateService.setSelectedProductId(productId);
        this.recipeStateService.setLobs(p.data);
      });
    } else {
      this.filteredLobList = [];
      this.recipeStateService.setLobs(this.filteredLobList);
    }
    this.selectedLobId.setValue(null);
    this.filteredDataSet = [];
    this.selectedDatasetId.setValue(null);
    this.recipeStateService.setSelectedLobId(null);
    this.updateRecipeIdentification();
  }

  onSelectLob(lobId:any){
    if (lobId) {
      this.datadropService.DatasetByCustomerSourceProdactLoi(
        this.selectedCustomerId.value,
        this.selectedSourceSystemId.value,
        this.selectedProductId.value,
        lobId)
        .subscribe((p) => {
          this.filteredDataSet = p.data;
          this.recipeStateService.setSelectedLobId(lobId);
          this.recipeStateService.setDatasets(p.data);
        });
    } else {
      this.filteredDataSet = [];
      this.recipeStateService.setDatasets(this.filteredDataSet);
    }
    this.selectedDatasetId.setValue(null);
    this.recipeStateService.setSelectedDatasetId(null);
    this.updateRecipeIdentification();
  }

  onSelectDataset(datasetId:any){
    this.recipeStateService.setSelectedDatasetId(datasetId);
      this.updateRecipeIdentification();
  }

}
