import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FormChangedService {
    trackForm(form: FormGroup, destroy$: Subject<unknown>) {
        form.valueChanges.pipe(takeUntil(destroy$)).subscribe(x => {
            this.markFormChanged();
        });
    }

    private _formIsChanged = new BehaviorSubject<boolean>(null);
    formIsChanged$ = this._formIsChanged.asObservable();

    private _dependencyList: Array<() => boolean> = [];
    private _statusActive = false;

    public get isActive() {
        return this._statusActive;
    }

    addDependency(fnchanged: () => boolean) {
        this._dependencyList.push(fnchanged);
    }

    isChanged(): boolean {
        let isChangedDependency = false;
        for (const fn of this._dependencyList) {
            if (fn()) {
                isChangedDependency = true;
                break;
            }
        }
        let formIsChanged = false;
        this.formIsChanged$.subscribe((data) => (formIsChanged = data));

        return formIsChanged || isChangedDependency;
    }

    trackControlChanges(control: AbstractControl, destroy$: Subject<any>) {
        control.valueChanges.pipe(takeUntil(destroy$)).subscribe(x => {
            this.markFormChanged();
        });
    }

    markFormChanged() {
        if (!this._statusActive)
            return;
        this._formIsChanged.next(true);
    }

    resetAndStartListening() {
        this._statusActive = true;
        this._formIsChanged.next(false);
    }

    disable() {
        this._statusActive = false;
    }

    ngOnDestroy() {
        this._formIsChanged.unsubscribe();
    }
}
