import { PolicyLookupResponse } from '@uw-verisk/business-ui';
import { VIEW_POLICY_INFO } from '../actions/policy-lookup-action';

export function policyInfoReducer(state: PolicyLookupResponse, action) {
  switch (action.type) {
    case VIEW_POLICY_INFO:
        return action.payload;
    default:
        return state;
    }
}