import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable()
export class RecipeStateStoreService {
  constructor(private router: Router) {
    this.listenToRouteChanges();
  }

  private listenToRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.includes('/recipe/')) {
          this.reset();
        }
      });
  }

  private recipeState = {
    customers: [],
    sourceSystems: [],
    products: [],
    lobs: [],
    datasets: [],
    selectedCustomerId: null,
    selectedSourceSystemId: null,
    selectedProductId: null,
    selectedLobId: null,
    selectedDatasetId: null,
  };

  getRecipeState() {
    return this.recipeState;
  }

  setCustomers(customers) {
    this.recipeState.customers = customers;
  }

  setSourceSystems(sourceSystems) {
    this.recipeState.sourceSystems = sourceSystems;
  }

  setProducts(products) {
    this.recipeState.products = products;
  }

  setLobs(lobs) {
    this.recipeState.lobs = lobs;
  }

  setDatasets(datasets) {
    this.recipeState.datasets = datasets;
  }

  setSelectedCustomerId(selectedCustomerId) {
    this.recipeState.selectedCustomerId = selectedCustomerId;
  }

  setSelectedSourceSystemId(selectedSourceSystemId) {
    this.recipeState.selectedSourceSystemId = selectedSourceSystemId;
  }

  setSelectedProductId(selectedProductId) {
    this.recipeState.selectedProductId = selectedProductId;
  }

  setSelectedLobId(selectedLobId) {
    this.recipeState.selectedLobId = selectedLobId;
  }

  setSelectedDatasetId(selectedDatasetId) {
    this.recipeState.selectedDatasetId = selectedDatasetId;
  }

  reset = () => {
    this.recipeState = {
      customers: [],
      sourceSystems: [],
      products: [],
      lobs: [],
      datasets: [],
      selectedCustomerId: null,
      selectedSourceSystemId: null,
      selectedProductId: null,
      selectedLobId: null,
      selectedDatasetId: null,
    };
  };

  resetExceptCustomer() {
    this.recipeState = {
      ...this.recipeState,
      sourceSystems: [],
      products: [],
      lobs: [],
      datasets: [],
      selectedSourceSystemId: null,
      selectedProductId: null,
      selectedLobId: null,
      selectedDatasetId: null,
    };
  }

  resetExceptCustomerAndSourceSystem() {
    this.recipeState = {
      ...this.recipeState,
      products: [],
      lobs: [],
      datasets: [],
      selectedProductId: null,
      selectedLobId: null,
      selectedDatasetId: null,
    };
  }

  resetExceptCustomerSourceSystemAndProduct() {
    this.recipeState = {
      ...this.recipeState,
      lobs: [],
      datasets: [],
      selectedLobId: null,
      selectedDatasetId: null,
    };
  }

  resetExceptCustomerSourceSystemProductAndLob() {
    this.recipeState = {
      ...this.recipeState,
      datasets: [],
      selectedDatasetId: null,
    };
  }
}
