import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'file-status-renderer',
    template: `
    <div *ngIf="fileUploaded" class="icon-wrapper">
        <i class="fa-regular fa-circle-check fa-xl uploaded-icon" style="color: #28ff00;"></i>
        <div class="conversion-icon" *ngIf="isConvertedFromExcel">
            <i class="fas fa-file"></i>
            <i class="fas fa-arrow-right"></i>
            <i class="fas fa-file-excel"></i>
        </div>
    </div>
    <span *ngIf="!fileUploaded" class="status-text">
        {{ params.data.fileState }}
    </span>
`,
    styles: [`
    .icon-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;
        min-height: 30px;
    }
    
    .conversion-icon i,
    .uploaded-icon {
        flex-shrink: 1;
        max-width: 100%;
    }
    
    .status-text {
        display: flex;
        align-items: center;
        margin-left: 10px;
        align-self: center; /* Center the icon if it's alone */
    }
`]
})

export class FileStatusRenderer implements ICellRendererAngularComp {
    public params: ICellRendererParams;
    public fileUploaded = false;
    public isConvertedFromExcel = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.fileUploaded = params.data.fileState === "Uploaded";
        this.isConvertedFromExcel = params.data.convertedFrom === "Excel_.xls" || params.data.convertedFrom === "Excel_.xlsx";
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}