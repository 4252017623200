import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { IAppConfig } from 'src/app/models/config';

@Component({
  selector: 'app-s3',
  templateUrl: './s3.component.html'
})

export class S3Component implements OnInit {
  s3Config = { getObjectsUrl: '', downloadUrl: '', basePath: '' }
  appConfig: IAppConfig;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appConfig = this.appService.readConfig();
    this.s3Config.getObjectsUrl = this.appConfig.apiUrl + 'api/AWSS3/GetS3ObjectList';
    this.s3Config.downloadUrl = this.appConfig.apiUrl + 'api/AWSS3/DownloadS3Object';
    this.s3Config.basePath = 'Contribution/Assets/CoverageVerifier';
  }
}
