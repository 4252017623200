import { Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import {
  VrskOktaLoginFlowComponent,
  VrskGatewayLoginGuard,
  VrskAuthOktaLogoutComponent,
} from '@uw-verisk/auth-okta';
import { RoleGuard } from './components/shared/guards/role-guard';

export const rootRouterConfig: Routes = [
  {
    path: `auth/oktaLoginFlow`,
    component: VrskOktaLoginFlowComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'file-explorer',
    canActivate: [RoleGuard ,OktaAuthGuard],
  },
  { path: 'auth/callback', component: OktaCallbackComponent },
  { path: 'auth/logged-out', pathMatch: 'full', redirectTo: 'file-explorer' },
  {
    path: 'auth/logout',
    component: VrskAuthOktaLogoutComponent,
  },
  {
    path: 'recipe',
    canActivate: [RoleGuard ,OktaAuthGuard],
    loadChildren: () =>
      import('./feature-recipe/recipe.module').then((m) => m.MergeModule),
  },
  {
    path: 'file-explorer',
    canActivate: [RoleGuard ,OktaAuthGuard],
    loadChildren: () =>
      import('./feature-file-explorer/file-explorer.module').then(
        (m) => m.FileExplorerModule
      ),
  },
  {
    path: 'customer',
    canActivate: [RoleGuard, OktaAuthGuard],
    loadChildren: () =>
      import('./customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'dataset',
    canActivate: [RoleGuard ,OktaAuthGuard],
    loadChildren: () =>
      import('./dataset/dataset.module').then((m) => m.DatasetModule),
  },
  {
    path: 'reports',
    canActivate: [RoleGuard ,OktaAuthGuard],
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
];
