import { Directive, HostListener, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[formControlName][appNullValue]'
})

export class NullDefaultValueDirective implements OnInit {
  
  constructor(private ngControl: NgControl) {}

  ngOnInit () {
    const initialOnChange = (this.ngControl.valueAccessor as any).onChange;
  
    (this.ngControl.valueAccessor as any).onChange = (value) => initialOnChange(this.onInputChange(value));
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.ngControl.valueAccessor.writeValue(this.onInputChange(event));
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.ngControl.control.setValue(this.onInputChange(event.target.value));
  }
  
  onInputChange(event) {
    return event === '' ? null : event;
  }
}
