import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { BtnCellIconRendererComponent } from "./ag-gr/BtnCellIconRendererComponent";
import { RecipeIdentificationPanelComponent } from "./recipe-identification-panel/recipe-identification-panel.component";
import { NgSelectFixDirective } from './ng-select/ng-select-fix.directive';
import { FolderLegendComponent } from "./legend/folder-legend.component";
import { FolderGridComponent } from "./folder-grid/folder-grid.component";
import { AgGridModule } from "ag-grid-angular";
import { GridListComponent } from "./grid-list/grid-list.component";
import { ActionButtonsRendererComponent } from "./ag-gr/action-buttons/action-buttons-renderer.component";
import { FormHeaderComponent } from "./form-header/form-header.component";
import { FormChangedService } from "../services/form-changed.service";
import { NullDefaultValueDirective } from "./directives/null-default-value.directive";
import { DialogModule } from "primeng/dialog";
import { PdpAlertComponent } from "./pdp-alert/pdp-alert.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgSelectModule,
        ReactiveFormsModule,
        AgGridModule,
        DialogModule
    ],
    declarations: [
        RecipeIdentificationPanelComponent, 
        BtnCellIconRendererComponent, 
        NgSelectFixDirective, 
        FolderLegendComponent, 
        FolderGridComponent,
        GridListComponent,
        ActionButtonsRendererComponent,
        FormHeaderComponent,
        NullDefaultValueDirective,
        PdpAlertComponent
    ],
    exports: [
        RecipeIdentificationPanelComponent, 
        BtnCellIconRendererComponent, 
        NgSelectFixDirective, 
        FolderLegendComponent, 
        FolderGridComponent,
        GridListComponent,
        ActionButtonsRendererComponent,
        FormHeaderComponent,
        NullDefaultValueDirective,
        PdpAlertComponent
    ],
    providers: [FormChangedService],
})
export class SharedModule { }