import { IAppConfig } from '@uw-verisk/common-ui';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
})
export class LeftNavComponent implements OnInit {
  items: MenuItem[];
  appConfig: IAppConfig;
  hideNav: boolean;
  selectedItem: MenuItem = {label: 'Coverage Verifier'};
  navHeader: string=  'Product Menu';
  displayDocuments: boolean = false;
  displayUserMgmt: boolean = false;
  displayPolicyRna: boolean = false;
  displayPolicySearch: boolean = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.checkPermissions();
    this.items = [
      { label: 'Documents', visible: this.displayDocuments},
      { label: 'Policy Search', visible: this.displayPolicySearch},
      { label: 'Policy R&A', visible: this.displayPolicyRna},
      { label: 'User Management', visible: this.displayUserMgmt},
      { label: 'Spinner' , command: ()=> this.navigate('/spinner')},
      { label: 'Grid' , command: ()=> this.navigate('/grid')}
    ]

    this.appConfig = {
      label: 'Select Product',
      placeholder: 'Select product',
      menuItems: [
        {name: 'Coverage Verifier'},
        {name: 'A+ Auto'},
        {name: 'A+ Property'}
      ]
    }
  }

  navigate(route: string) {
    this.hideNav = true;
    this.router.navigateByUrl(route);
  }

  checkPermissions() {
    // const productCode: string = 'CV';
    // if(this.response?.permissions){
    // this.response.permissions.filter(val => {
    //   if (val.resourceName === 'CPP View' && val.mayView && val.productCode === productCode) {
    //      this.displayDocuments = true;
    //      return this.displayDocuments;
    //   } else if (val.resourceName === 'CPP Load' && val.mayView && val.productCode === productCode) {
    //      this.displayPolicySearch = true;
    //   } else if (val.resourceName === 'CPP Search' && val.mayView && val.productCode === productCode) {
    //     this.displayPolicyRna = true;
    //   } else if (val.resourceName === 'CPP Admin' && val.mayEdit && val.productCode === productCode) {
    //      this.displayUserMgmt = true;
    //   }
    // });
    //  }
  }
}
