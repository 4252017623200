import { Component } from '@angular/core';
import * as config from '../../../../assets/jsons/config.json';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent {

  redirectUri = config.redirectUrl;
  clientId = config.clientId;
  loginUrl = config.loginUrl;
  issuer = config.issuer;
  responseType = config.responseType;
}
