import { PolicyLookupResponse } from '@uw-verisk/business-ui';
import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { VIEW_PERMISSIONS } from './store/actions/user-preferences-action';
import { AppState } from './store/app.state';
import { UserPreferenceResponse } from './store/model/user-preferences.interface';
import { VIEW_POLICY_INFO } from './store/actions/policy-lookup-action';
import { IAppConfig } from './models/config';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { UserService } from './core/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  displayNav: boolean;
  appConfig: IAppConfig;
  userApiUrl: string;
  lookupApiUrl: string;
  displayContent: boolean;
  userResponse: any;
  isDisclaimerLoaded: boolean;
  isSuperUser: boolean;
  isImpersonateMode: boolean;

  public isAuthenticated$!: Observable<boolean>;
  public name$: Observable<string>;

  constructor(
    private appService: AppService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private store: Store<AppState>,
    private userService: UserService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public oktaAuthSvc: OktaAuthStateService
  ) {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        // const gtmTag = {
        //   event: 'page',
        //   pageName: item.url,
        // };
        // uncomment below code for GoogleTagManager analytics
        // this.gtmService.pushTag(gtmTag);
      }
    });
  }

  async ngOnInit() {
    this.appConfig = this.appService.readConfig();
    if (this.oktaAuth) {
      this.oktaAuth.getUser().then(async (user) => {
        this.appConfig.username = user.preferred_username;
        this.appConfig.userINumber = this.appConfig.username.split('@')[0];
      });
    }
  }

  userInfo(resp: UserPreferenceResponse) {
    this.displayNav = true;
    this.store.dispatch({
      type: VIEW_PERMISSIONS,
      payload: resp,
    });
  }

  policyInfo(resp: PolicyLookupResponse) {
    this.store.dispatch({
      type: VIEW_POLICY_INFO,
      payload: resp,
    });
  }
}
