import { Observable } from "rxjs/internal/Observable";

export function serverCall(cb: (data: any) => void, httpRequest: Observable<any>) {
    httpRequest.toPromise().then((responce) => {
        if (responce == null)
            return;
        if ((<any>responce).data) {
            cb && cb(responce.data);
        } else {
            cb && cb(responce);
        }
    });
}

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const array = new Uint8Array(1);
        window.crypto.getRandomValues(array);
        const r = array[0] % 16;
        const v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}