import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-pdp-alert',
    templateUrl: './pdp-alert.component.html'
})

export class PdpAlertComponent {
    @Input() visible: boolean;
    @Input() header: string;
    @Input() message: string;

    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    onHide(event: any): void {
        this.onClose.emit(this.visible);
    }
}