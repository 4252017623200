import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-button-icon-renderer',
    template: `<span class="ag-cell-delete-span" (click)="onClick($event)" tooltip="Delete">
                    <i class="fa fa-edit fa-xl"></i>
             </span> `
})
export class BtnCellIconRendererComponent implements ICellRendererAngularComp {

    params: { onClick: any; node: any; };

    agInit(params): void {
        this.params = params;
    }

    refresh(params?: any): boolean {
        return true;
    }

    onClick($event) {
        const params = {
            event: $event,
            rowData: this.params.node.data
        };
        this.params.onClick(params);
    }
}
