import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { UserService } from 'src/app/core/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  hasRole: (resource: string) => boolean;

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private userService: UserService,
    private appService: AppService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.oktaAuth) {
      let config = this.appService.readConfig();
      return this.oktaAuth.getUser().then(async (user) => {
        config.username = user.preferred_username;
        config.userINumber = config.username.split('@')[0];
        // await this.userService.loadUserInfo();
        // return this.canEditOrView(route.data['permission']);
        return true;
      });
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.oktaAuth) {
      let config = this.appService.readConfig();
      return this.oktaAuth.getUser().then(async (user) => {
        config.username = user.preferred_username;
        config.userINumber = config.username.split('@')[0];
        // await this.userService.loadUserInfo();
        return true;
        // return this.canEditOrView(route.data['permission']);
      });
    }
  }

  private canEditOrView(permissionData: string) {
    const permissions = this.parse(permissionData);

    for (const permission of permissions) {
      if (this.hasRole(permission)) {
        return true;
      }
    }

    return false;
  }

  private parse(permission: string | string[]): string[] {
    let permissions: string[] = [];
    if (typeof permission === 'string') {
      permissions = [permission];
    } else if (Array.isArray(permission)) {
      permissions = permission;
    }
    return permissions;
  }
}
