import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Config } from '../models/config';
import { HttpBaseService } from "./http-services/http-base";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";
import * as moment from "moment";

@Injectable()
export class StorageService {
    apiUrl: string;
    user: string;

    constructor(private http: HttpClient, private httpService: HttpBaseService, private appServcie: AppService) {
        this.apiUrl = environment.datadropApiUrl;
        this.user = Config.username;
    }

    getIdentity(): string{
        return this.appServcie.readConfig().userINumber + "@verisk.com";
    }
    getList(path: string): Observable<any> {
        return this.httpService.get<any>(`storage/list?path=${path}`);
    }

    createFolder(customerId: number, sourceSystemId: number, productId: number, lobId: number, folderName: string): Observable<any> {
        let request = {
            customerId: customerId,
            sourceSystemId: sourceSystemId,
            productId: productId,
            lobId: lobId,
            folderName: folderName
        };
        return this.httpService.create<any>('storage/folder', request);
    }

    getScope(customerId: number, sourceSystemId: number, productId: number, lobId: number): Observable<any> {
        let query = `customerId=${customerId}&sourceSystemId=${sourceSystemId}&productId=${productId}&lobId=${lobId}`;
        return this.httpService.get<any>(`scopes?${query}`);
    }

    getUploadLink(scopeId: number, folderName: string, fileName: string, uploadTimeLocal?: Date): Observable<any> {
        const headers = new HttpHeaders().set('identity', this.getIdentity());
        const uploadTime = moment(uploadTimeLocal).format("MM/DD/YYYY HH:mm:ss") ?? moment().format("MM/DD/YYYY HH:mm:ss"); //uploadTimeLocal is used for tests
        console.log('uploadTime: ', uploadTime);
        return this.http.get(`${this.apiUrl}storage/uploadlink?scopeId=${scopeId}&folderName=${folderName}&fileName=${fileName}&uploadTime=${uploadTime}`,
            {
                headers,
                responseType: 'text'
            })
    }

    uploadFile(uploadLink: string, file: File) {
        const headers = new HttpHeaders().set('x-amz-server-side-encryption', 'aws:kms')
            .set('x-amz-server-side-encryption-aws-kms-key-id', this.appServcie.readConfig().kmsKeyId)
            .set('Content-Type', file.type);
        return this.http.put(uploadLink, file, { headers: headers, reportProgress:true, observe: "events" });
    }

    deleteFileQueueId(id: string): Observable<any> {
        return this.httpService.delete<any>(`queue/${id}`);
    }
}