export const environment = {
  production: false,
  datadropApiUrl: '',
  numberItemsInPage: 100,
};

function getDataSynchronous(cb) {
  const url = "../assets/jsons/config.json";
  const request = new XMLHttpRequest();
  request.open('GET', url, false);
  request.send();
  if (request.status === 200) {
    return cb(JSON.parse(request.response));
  } else {
    throw new Error('request CONFIG.JSON failed');
  }
}

getDataSynchronous((config) => {
  environment.datadropApiUrl = config.apiUrl;
});
