import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IMenuConfig, IHeaderTitle } from '@uw-verisk/common-ui';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { AppState } from 'src/app/store/app.state';
import { AppService } from 'src/app/app.service';
import { IAppConfig } from 'src/app/models/config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profile: string;
  appConfig: IAppConfig;
  title :IHeaderTitle;

  constructor(private appService : AppService, 
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, 
    private router: Router, private store: Store<AppState>) { 
  }

  ngOnInit(){
    this.appConfig = this.appService.readConfig();
    this.title = {
      name: this.appConfig.applicationName,
    }
  }

   
  menuItems: IMenuConfig[] = []

  public onLogout() {
    this.oktaAuth?.signOut();
  }

  onTitleClick() {
    this.router.navigateByUrl('/');
  }
}
