import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBaseDto } from '../dto/IBaseDto';
import { IDatasetDto } from '../dto/DatasetDto';
import { HttpBaseService } from './http-services/http-base';
import { IStepMapDto } from '../dto/IStepMapDto';
import { IMapAttributeDto } from '../dto/IMapAttributeDto';

@Injectable()
export class HttpRecipeStepMap {

  constructor(private httpService: HttpBaseService) { }

  harmonizedDatasets(): Observable<IBaseDto<Array<IDatasetDto>>> {
    return this.httpService.getAllPages<IBaseDto<Array<IDatasetDto>>>(`HarmonizedDatasets`);
  }

  getMapStepById(stepId: number): Observable<IStepMapDto> {
    return this.httpService.get<IStepMapDto>(`MapSteps/${stepId}`);
  }

  getMapAttributeByStepId(stepId: number): Observable<IBaseDto<Array<IMapAttributeDto>>> {

    return this.httpService.getAllPages<IBaseDto<Array<IMapAttributeDto>>>(`MapSteps/${stepId}/mapAttribute`);
  }

  updateMapAttribute(dto: { attributes: IMapAttributeDto[], stepId: number }) {
    if (dto.stepId != 0) {
      return this.httpService.patch<IStepMapDto>(`MapSteps/${dto.stepId}/mapAttribute`, dto);
    }
  }

  saveMapStep(dto: IStepMapDto): Observable<IStepMapDto> {
    if (dto.stepId == 0) {
      return this.httpService.create<IStepMapDto>(`MapSteps`, dto);
    } else {
      return this.httpService.update<IStepMapDto>(`MapSteps/${dto.stepId}`, dto);
    }
  }

}


