import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[appNgSelectFix]'
})
export class NgSelectFixDirective implements OnDestroy {
  @Input() appNgSelectFix = null;
  parentScrollElement: Element;

  constructor(private select: NgSelectComponent, private el: ElementRef) {
    select.openEvent.subscribe(x => {

      this.parentScrollElement = document.querySelector(this.appNgSelectFix);
      this.parentScrollElement.addEventListener("scroll", this.scrollEvent);
    });

    select.closeEvent.subscribe(x => {
      this.parentScrollElement.removeEventListener("scroll", this.scrollEvent);
      this.parentScrollElement = null;
    });
  }

  ngOnDestroy(): void {
    this.parentScrollElement?.removeEventListener("scroll", this.scrollEvent);
  }

  scrollEvent = (event) => {
    if (this.select.isOpen)
      this.select.close();
  }

}
