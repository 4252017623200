import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from './models/config';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private configuration: any;

  constructor(private httpClient: HttpClient) { }

  async setConfig(): Promise<IAppConfig> {
    await this.httpClient
      .get<IAppConfig>('../assets/jsons/config.json')
      .toPromise()
      .then((config) => {
        this.configuration = config;
      });
    return this.configuration;
  }

  readConfig(): IAppConfig {
    console.log(this.configuration);
    return this.configuration;
  }

  readUrl() {
    return this.configuration.apiUrl;
  }
}