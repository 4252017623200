import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class S3Interceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // doing this because requests may be coming from s3.amazonaws.com or 
    // it can also come from s3.us-west-2.amazonaws.com
    // this is happening from us-west region
    // we should not include bearer tokens while sending requests to s3
    if (/s3(\.[a-z0-9-]+)?\.amazonaws\.com/.test(req.url)) {
      // Clone the request and remove the Authorization header
      req = req.clone({ headers: req.headers.delete('Authorization') });
    }
    return next.handle(req);
  }

}