import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-button-icon-renderer',
    templateUrl: './action-buttons-renderer.component.html'
})
export class ActionButtonsRendererComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
      this.params = params;
    }

    refresh(params?: ICellRendererParams): boolean {
      return true;
    }

    onClickEdit($event) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
        node: this.params.node
      };
      this.params.context.onClickEdit(params);
    }

    onClickRun($event) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.context.onClickRun(params);
    }

    onClickDelete($event) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.context.onClickDelete(params);
    }

    onClickDownload($event){
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.context.onClickDownload(params);
    }
}
