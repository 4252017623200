import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, IGetRowsParams } from "ag-grid-community";
import { IRecipeGridListConfig } from "./model/grid-list.config";
import { HttpBaseService } from "src/app/services/http-services/http-base";
import { ActionButtonsRendererComponent } from "../ag-gr/action-buttons/action-buttons-renderer.component";
import * as moment from 'moment';
import { HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'app-grid-list',
    templateUrl: './grid-list.component.html',
    styleUrls: ['./grid-list.component.css'],
})

export class GridListComponent implements OnInit, OnDestroy {
    @Input() config: IRecipeGridListConfig;
    @Output() rowId: EventEmitter<number | Record<string, any>> = new EventEmitter();
    @Output() rowDeleted = new EventEmitter<any>();
    @Output() rowAdded = new EventEmitter<any>();
    @Output() refreshGrid = new EventEmitter<any>();
    @Output() clientRowDataChanged = new EventEmitter<any[]>();
    destroy$ = new Subject();

    gridApi: GridApi;
    columnApi: ColumnApi;
    gridOptions: GridOptions = {
        pagination: true,
        paginationPageSize: 100,
        cacheBlockSize: 100,
        rowModelType: 'infinite',
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        infiniteInitialRowCount: 1,
        maxBlocksInCache: 2,
        defaultColDef: {
            flex: 1,
            resizable: true,
            sortable: true,
            unSortIcon: true, 
            sortingOrder:['asc', 'desc']
        },
        animateRows: true
    };
    defaultColumns: ColDef[];
    columnDefs: ColDef[];
    rowData = [];

    constructor(private httpService : HttpBaseService) { }

    ngOnInit() {
      this.setConfig();
      this.refreshGrid?.subscribe(() => {
        this.refresh();
      });
    }
    
    setConfig() {
        if(this.config){
            this.defaultColumns = [
                { headerName: 'Created By', field: 'createdBy', hide: this.isDefaultColumnsHidden() },
                { headerName: 'Created Date', field: 'createdDate', hide: this.isDefaultColumnsHidden(),
                    cellRenderer: (r) => {
                        if (r.data?.createdDate != null)
                            return moment.utc(r.data.createdDate).local().format('MM/DD/YYYY');
                    } 
                },
                { headerName: 'Modified By', field: 'modifiedBy', hide: this.isDefaultColumnsHidden() },
                { headerName: 'Modified Date', field: 'modifiedDate', hide: this.isDefaultColumnsHidden(), sort: this.decideSortColumn(),
                    cellRenderer: (r) => {
                        if (r.data?.modifiedDate != null)
                            return moment.utc(r.data.modifiedDate).local().format('MM/DD/YYYY');;
                    } 
                },
                { field: 'Action', sortable: false, hide: this.hideActionColumn(), colId:'action',
                    cellRenderer: ActionButtonsRendererComponent,
                    cellRendererParams:{
                        editable: this.config?.editUrl !== null,
                        runnable: this.config?.runUrl,
                        deletable: this.config?.deleteUrl !== null,
                        downloadable: this.config?.downloadable,
                        context: {
                            onClickEdit: this.editRow,
                            onClickDelete: this.deleteRow,
                            onClickRun: this.runRow, // I know the name is weird, but since it's for recipe...
                            onClickDownload: this.download
                        },
                    }
                }
            ];
            
            const columnDefs = this.config?.columnDefs ?? this.config?.columnDefs;
            this.columnDefs = [...columnDefs, ...this.defaultColumns];
            this.gridOptions.rowModelType =  this.config.areInlineActionsEnabled ? 'clientSide' : 'infinite';
        }
    }

    private decideSortColumn(): "asc" | "desc" {
        if(this.config.defaultSortColumn){
            return null;
        }
        return !this.config?.areInlineActionsEnabled ? 'desc' : null;
    }

    private hideActionColumn(): boolean {
        return this.config?.editUrl == null && this.config?.deleteUrl == null
         && this.config?.runUrl == null && !this.config.downloadable;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setConfig();
        if (this.gridApi) {
            this.refresh();
        }
    }
    
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi; 
        this.setDataSource();
    }
       
    refresh() {
        if (!this.config?.areInlineActionsEnabled) {
            this.gridApi.setDatasource(null);
        }
        this.columnApi.resetColumnState();
        this.setDataSource();
    }

    addRow = () => {
        if (this.config?.areInlineActionsEnabled) {
            const newRow = { id: 0 };
            this.gridApi.applyTransaction({ add: [newRow] });
            this.rowAdded.emit(newRow);
            return;
        }
        this.rowId.emit(0);
    }

    editRow = (params) => {
        this.rowId.emit(params.rowData.id || params.rowData.recipeId);
    }

    runRow = (params) => {
        this.rowId.emit({recipeId: params.rowData.recipeId, mode: 'run'});
    }

    download = params => {
        this.rowId.emit({rowData: params.rowData, mode: 'download'})
    }

    deleteRow = (params) => {
        if (this.config?.recipeGrid){
            this.rowDeleted.emit({data: params.rowData});
            return;
        }
        if (this.config?.areInlineActionsEnabled) {
            this.gridApi.applyTransaction({
                remove: [params.rowData],
            });
            this.rowDeleted.emit(params.rowData);
            return;
        }
        this.httpService.delete<any>(`${this.config.deleteUrl}/${params.rowData.id}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
            next: () => {
                this.setDataSource();
            },
            error: (err) => {
                this.rowDeleted.emit({ rowData: params.rowData, errorResponse: err });
            } 
        });
    }

    setDataSource() {
        if (this.gridOptions.rowModelType != 'infinite') {
            const filteredClientRowData = this.config?.clientRowData?.filter(row => row.id !==  0);
            this.gridApi.setRowData(filteredClientRowData);
            this.clientRowDataChanged.emit(filteredClientRowData);
            return;
        }
        const datasource = {
            getRows: (params: IGetRowsParams) => {
                const columnName = params.sortModel.length > 0 ? params.sortModel[0].colId : null;
                const isAscending = !!(params.sortModel.length > 0 && params.sortModel[0].sort === 'asc');
                let queryParams = new HttpParams();
                const pageNum = Math.round(params.endRow / this.gridOptions.paginationPageSize);
                queryParams = queryParams.append("pageNumber", `${pageNum}`);
                queryParams = queryParams.append("pageSize", `${this.gridOptions.paginationPageSize}`);
                if (columnName != null && this.config?.getUrl != null) {
                    queryParams = queryParams.append("sortColumn.columnName", `${columnName}`);
                    queryParams = queryParams.append("sortColumn.isAscending", `${isAscending}`);
                    this.httpService.get<any>(this.config.getUrl, queryParams).subscribe(result=> {
                        if (result.data.length === 0) {
                            params.successCallback([], 0);
                            this.gridApi.showNoRowsOverlay();
                        } else {
                            this.gridApi.hideOverlay();
                            params.successCallback(result.data, result.totalCount);
                        }
                    });
                } else if (this.config?.clientRowData) {
                    this.gridApi.hideOverlay();
                    params.successCallback(this.config?.clientRowData, this.config?.clientRowData.length);
                } else {
                    params.successCallback([], 0);
                    this.gridApi.showNoRowsOverlay();
                }
            } 
        }
        this.gridApi.setDatasource(datasource);
    }

    isDefaultColumnsHidden(): boolean {
        return this.config?.areDefaultColumnsEnabled === false;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}