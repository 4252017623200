<!-- Copyright © 2024 Insurance Services Office, Inc. All rights reserved -->

<p-dialog [header]="header" 
    [(visible)]="visible" 
    [style]="{ width: '50vw' }" 
    (onHide)="onHide($event)">
    <p style="white-space: pre-line;">
        {{ message }}
    </p>
    <ng-content></ng-content>
</p-dialog>